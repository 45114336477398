<template>
    <span @click.stop="toggleConfigureTagBox">
        <div ref="tagsBox" class="bg-transparent transition-all duration-300 rounded-3xl cursor-pointer flex flex-row"
                :class="{ 'mt-0.5': !isBoxActive }">
            <span class="flex items-center px-1">
                <i :class="['pi', 'text-xl', 'transition-transform', 'duration-300', 
                        isBoxActive ? 'pi-chevron-circle-left rotate-180 text-gray-500 dark:text-gray-400' : 'pi-tags rotate-0 text-primary-500']" />
            </span>
            <span v-for="tag in tagsArray" :key="tag.id" class="inline-flex items-center mx-1">
                <Chip class="text-xs flex items-center w-auto max-w-max whitespace-nowrap px-2 relative overflow-hidden">
                    <span class="flex justify-between items-center w-full px-1">
                        <span class="px-1 text-xs truncate">{{ tag.name }}</span>
                        <span :class="{
                            'pi pi-times rounded-xl text-xs p-1 cursor-pointer': isBoxActive,
                        }" @click="unlinkTag(tag.id)" />
                    </span>
                </Chip>
            </span>
            <span v-if="isBoxActive || tagsArray.length === 0" class="inline-flex items-center mx-1"
                @click.stop="filteredAvailableTags.length === 0 ? openTagManagementDialog($event) : openTagAssignmentOverlay($event)">
                <Chip class="text-xs flex items-center w-auto px-2 relative"
                    :class="{ ' text-black dark:text-white hover:opacity-80': isBoxActive }">
                    <span class="flex justify-between items-center "
                        :class="{ 'pi pi-plus rounded-full p-0.5 text-xs cursor-pointer': isBoxActive || tagsArray.length === 0 }" />
                </Chip>
            </span>
            <OverlayPanel ref="tagAssignmentOverlay" append-to="body" class="flex flex-row"
                @hide="cancelQuickCreateTag">
                <div class="max-h-96 overflow-y-auto block mb-2">
                    <div v-for="availableTag in filteredAvailableTags" :key="availableTag.id"
                        @click.stop="assignTag(availableTag)">
                        <Button :label="availableTag.name" text severity="secondary" class="text-sm w-32 max-w-32" />
                    </div>
                    <template v-if="isCreatingAndAssigningTag">
                        <InputText id="createTagInputText" v-model="newTagName" type="text" placeholder="Nouveau tag"
                            class="text-sm max-w-32 text-center" @keyup.enter="createTag" @vue:mounted="textFocus" />
                    </template>
                </div>
                <div class="flex justify-center space-x-1">
                    <Button v-if="isCreatingAndAssigningTag" icon="pi pi-check"
                        class="p-button-text p-button-success text-xs pr-1" @click.stop="createTag" />
                    <Button v-if="isCreatingAndAssigningTag" icon="pi pi-times"
                        class="p-button-text p-button-danger text-xs pr-1" @click.stop="cancelQuickCreateTag" />
                    <Button v-if="!isCreatingAndAssigningTag" class="p-button-text text-xs pr-1" icon="pi pi-plus"
                        @click="startCreatingTag" />
                    <Button class="p-button-text text-xs" icon="pi pi-cog" @click="openTagManagementDialog" />
                </div>
            </OverlayPanel>
        </div>
        <LayoutTagManagementBox :visible="tagManagementDialogVisible" :all-tags="allTags"
            @hide="closeTagManagementDialog" />
    </span>
</template>


<script setup lang="ts">
const currentUser = useCurrentUser().value;
const userId = currentUser.id;

const allTags = ref<Tag[]>([]);
const tagsArray = ref<Tag[]>([]);

const isBoxActive = ref(false);
const tagAssignmentOverlay = ref<OverlayPanel | null>(null);

const isCreatingAndAssigningTag = ref(false);
const newTagName = ref("");

const tagManagementDialogVisible = ref(false);
const visibility = ref<"admin" | "self" | "public">("self");

const { fileId, fileTags } = defineProps<{ fileId: string, fileTags: Tag[] }>();

const currentAcquisitionFile = ref<{ id: string } | null>(null);
if (!fileId) {
    const file = useCurrentAcquisitionFile();
    currentAcquisitionFile.value = { id: file.value.id };
} else {
    currentAcquisitionFile.value = { id: fileId.toString() };
}



interface Tag {
    id: string;
    name: string;
    visibility: "admin" | "self" | "public";
}

const textFocus = (el: any) => {
    el.el?.focus();
}

const tagsBox = ref(null);
const preventOutsideClick = ref(false);

const toast = useToast();

function handleClickOutside(event: MouseEvent) {
    if (preventOutsideClick.value) return;
    if (isBoxActive.value && tagsBox.value && tagsBox.value !== null && !(tagsBox.value as HTMLElement).contains(event.target as Node) && filteredAvailableTags.value.length !== 0) {
        isBoxActive.value = false;
    }
}

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});

const isUserAdmin = isAdmin(currentUser);
const queryResponse = await useFetch(`/api/tag?userId=${userId}&isUserAdmin=${isUserAdmin}`, { method: "GET" });

if (Array.isArray(queryResponse.data.value)) {
    allTags.value = (queryResponse.data.value as any[]).map(tag => ({
        id: tag.id.toString(),
        name: tag.name,
        visibility: tag.visibility,
    })) as Tag[];
} 

if (!fileTags) {
    const response = await useFetch(`/api/acquisitionFiles/${currentAcquisitionFile.value.id}/getAllTags?userId=${userId}&isUserAdmin=${isUserAdmin}`, { method: "GET" });
    if (Array.isArray(response.data.value)) {
        tagsArray.value = (response.data.value as any[]).map(tag => ({
            id: tag.id.toString(),
            name: tag.name,
        })) as Tag[];
    }
} else {
    tagsArray.value = fileTags;
}

const filteredAvailableTags = computed(() => {
    return allTags.value.filter(
        (availableTag: Tag) => !tagsArray.value.some((tag: Tag) => tag.id === availableTag.id)
    );
});

function toggleConfigureTagBox() {
    isBoxActive.value = !isBoxActive.value;
    closeTagAssignmentOverlay();
}

async function unlinkTag(tagId: string) {
    isBoxActive.value = false;
    closeTagAssignmentOverlay();

    const tagIndex = tagsArray.value.findIndex((existingTag: Tag) => existingTag.id === tagId);
    if (tagIndex !== -1) {
        tagsArray.value.splice(tagIndex, 1);
    }

    const { error } = await useFetch(`/api/acquisitionFiles/${currentAcquisitionFile.value.id}/removeTag?tagId=${tagId}`, {
        method: 'DELETE',
    });

    if (error.value) {
        console.error('Error removing tag:', error.value);
        const tag = tagsArray.value.find((tag: Tag) => tag.id === tagId);
        if (tag) {
            tagsArray.value.push(tag);
        }
    }
}

async function assignTag(tag: Tag) {
    if (!tagsArray.value.some((existingTag: Tag) => existingTag.id === tag.id)) {
        tagsArray.value.push(tag);
    }
    closeTagAssignmentOverlay();
    const { error } = await useFetch(`/api/acquisitionFiles/${currentAcquisitionFile.value.id}/addTag`, {
        method: 'POST',
        body: {
            tagId: tag.id
        }
    });

    if (error.value) {
        console.error('Error adding tag:', error.value);
        const tagIndex = tagsArray.value.findIndex((existingTag: Tag) => existingTag.id === tag.id);
        if (tagIndex !== -1) {
            tagsArray.value.splice(tagIndex, 1);
        }
    }
}

function openTagAssignmentOverlay(event: Event) {
    const overlayPanel = tagAssignmentOverlay.value as OverlayPanel;
    overlayPanel.toggle(event);
}

function closeTagAssignmentOverlay() {
    const overlayPanel = tagAssignmentOverlay.value as OverlayPanel;
    overlayPanel.hide();
}

function openTagManagementDialog() {
    closeTagAssignmentOverlay();
    tagManagementDialogVisible.value = true;
}

function closeTagManagementDialog() {
    tagManagementDialogVisible.value = false;
}

function cancelQuickCreateTag() {
    newTagName.value = '';
    isCreatingAndAssigningTag.value = false;
}

watch([newTagName, isCreatingAndAssigningTag], () => {
});

function startCreatingTag() {
    isCreatingAndAssigningTag.value = true;
    preventOutsideClick.value = true;
    setTimeout(() => {
        preventOutsideClick.value = false;
    }, 3000);
}

async function createTag() {
    if (!newTagName.value.trim()) return;

    const { data, error } = await useFetch('/api/tag', {
        method: 'POST',
        body: {
            name: newTagName.value.trim(),
            visibility: visibility.value,
            createdBy: userId,
        }
    });

    if (data.value?.error?.message === "Tag already exists") {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Tag existe déjà',
            life: 3000
        });
        return;
    } else if (data.value && Array.isArray(data.value) && data.value.length > 0) {
        const newTag: Tag = { id: data.value[0].id.toString(), name: newTagName.value.trim(), visibility: visibility.value };
        allTags.value.push(newTag);
        cancelQuickCreateTag();
    }
}

</script>
