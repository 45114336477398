<template>
    <Dialog v-model:visible="internalVisible" class="relative text-xs" modal @hide="handleDialogHide">
        <template #header>
            <div class="flex justify-center items-center w-full relative">
                <h3 class="flex-1 text-center">Gestion des Tags</h3>
            </div>
        </template>
        <div>
            <table class="w-full max-h-[244px] table-fixed overflow-y-auto mx-4 block">
                <thead v-if="allTags.length > 0">
                    <tr class="text-base">
                        <th class="text-left p-2 pe-6 border-b">Tag</th>
                        <th class="text-left p-2 border-b">Visibilité</th>
                        <th class="text-left p-2 ps-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tag in allTags" :key="tag.id" class="text-sm">
                        <td class="p-2 pe-10 pb-1">
                            <InputText v-if="editingTagId === tag.id" v-model="tag.name" placeholder="Tag Name"
                                class="text-left w-36 items-start" @keyup.enter="updateTag(tag)"/>
                            <span v-else class="text-base">{{ tag.name }}</span>
                        </td>
                        <td class="p-2 pe-4">
                            <template v-if="isAdmin(user)"> 
                                <Select v-model="tag.visibility" :options="visibilityOptions"
                                    :disabled="(!isAdmin(user)) || editingTagId !== tag.id" option-label="label"
                                    option-value="value" class="mr-2 min-w-28" />
                            </template>
                            <template v-else>
                                <div class="flex items-center">
                                    <i class="pi pi-lock mr-2 text-gray-500" />
                                    <span>{{ getVisibilityLabel(tag.visibility) }}</span>
                                </div>
                            </template>
                        </td>
                        <td class="p-2 ps-2">
                            <Button v-if="editingTagId === tag.id" icon="pi pi-check"
                                class="p-button-text text-green-400" @click="updateTag(tag)" />
                            <Button v-else
                                :icon="!isAdmin(user) && tag.visibility !== 'self' ? 'pi pi-lock' : 'pi pi-pencil'"
                                :disabled="(!isAdmin(user) && tag.visibility !== 'self')" class="p-button-text"
                                @click="startEdit(tag.id)" />
                            <Button v-if="editingTagId === tag.id" icon="pi pi-times"
                                class="p-button-danger p-button-text" @click="cancelEdit()" />
                            <Button v-else
                                :icon="!isAdmin(user) && tag.visibility !== 'self' ? 'pi pi-ban' : 'pi pi-trash'"
                                :disabled="(!isAdmin(user) && tag.visibility !== 'self')"
                                class="p-button-danger p-button-text" @click="deleteTag(tag.id)" />
                        </td>
                    </tr>
                    <tr v-if="isCreatingTag" class="text-sm">
                        <td>
                            <InputText v-model="newTagName" placeholder="Nouveau tag" @vue:mounted="textFocus" @keyup.enter="createTag" />
                        </td>
                        <td class="p-2">
                            <Select v-model="newTagVisibility" :options="visibilityOptions" :disabled="!isAdmin(user)"
                                option-label="label" option-value="value" class="mr-2 min-w-28" />
                        </td>
                        <td class="p-2">
                            <Button icon="pi pi-check" class="p-button-text" @click="createTag" />
                            <Button icon="pi pi-times" class="p-button-danger p-button-text" @click="cancelCreateTag" />
                        </td>
                    </tr>
                </tbody>
            </table>
            <Button v-if="!isCreatingTag" label="Créer un nouveau tag" icon="pi pi-plus" class="mt-4 w-full"
                @click="isCreatingTag = true" />
        </div>
    </Dialog>
</template>

<script setup lang="ts">
const isCreatingTag = ref(false);
const createdTag = ref<Tag | null>(null);
const newTagName = ref('');
const newTagVisibility = ref<"admin" | "self" | "public">("self");

const visibilityOptions = [
    { label: 'Privé', value: 'self' },
    { label: 'Public', value: 'public' },
    { label: 'Admin', value: 'admin' },
];

const isEditing = ref(false);
const editingTagId = ref<string | null>(null);

const toast = useToast();

interface Tag {
    id: string;
    name: string;
    visibility: "admin" | "self" | "public";
}

function getVisibilityLabel(visibilityValue: "admin" | "self" | "public") {
    const option = visibilityOptions.find(opt => opt.value === visibilityValue);
    return option ? option.label : visibilityValue;
}

const textFocus = (el: any) => {
    el.el?.focus();
}

const props = defineProps({
    visible: Boolean,
    allTags: {
        type: Array as PropType<Tag[]>,
        default: () => [],
    },
});


const allTags = ref<Tag[]>(props.allTags);
const internalVisible = ref(props.visible);
const user = useCurrentUser().value as unknown as { profile_type: string };

watch(
    () => props.visible,
    (newVal: boolean) => {
        internalVisible.value = newVal;
    }
);

watch(internalVisible, (newVal: boolean) => {
    if (!newVal) {
        cancelCreateTag();
    }
});

async function createTag() {
    if (!newTagName.value.trim()) return;

    const { data, error } = await useFetch('/api/tag', {
        method: 'POST',
        body: {
            name: newTagName.value.trim(),
            visibility: newTagVisibility.value,
            createdBy: user.id,
        }
    });

    if (data.value?.error?.message === "Tag already exists") {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Tag existe déjà',
            life: 3000
        });
        return;
    } else if (data.value && Array.isArray(data.value) && data.value.length > 0) {
        const newTag: Tag = { id: data.value[0].id.toString(), name: newTagName.value.trim(), visibility: newTagVisibility.value };
        allTags.value.push(newTag);
        createdTag.value = newTag;
        cancelCreateTag();
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Tag créé avec succès',
            life: 3000
        });
    } else {
        console.error('Error creating tag:', error.value?.message);
        return;
    }
}

function cancelCreateTag() {
    newTagName.value = '';
    isCreatingTag.value = false;
}

function startEdit(tagId: string) {
    isEditing.value = true;
    editingTagId.value = tagId;
}

async function updateTag(tag: Tag) {
    isEditing.value = false;
    editingTagId.value = null;

    const { data, error } = await useFetch(`/api/tag/${tag.id}`, {
        method: 'PATCH',
        body: {
            name: tag.name,
            visibility: tag.visibility
        }
    });

    if (error.value) {
        console.error('Error updating tag visibility:', error.value);
    } else {
        const tagIndex = allTags.value.findIndex((existingTag: Tag) => existingTag.id === tag.id);
        if (tagIndex !== -1) {
            allTags.value[tagIndex] = tag;
        }
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Tag mis à jour avec succès',
            life: 3000
        });
    }

}

function cancelEdit() {
    isEditing.value = false;
    editingTagId.value = null;
}

async function deleteTag(tagId: string) {
    const { error } = await useFetch(`/api/tag/${tagId}`, {
        method: 'DELETE',
    });

    if (error.value) {
        console.error('Error deleting tag:', error.value);
    } else {
        console.log('Tag deleted successfully:', tagId);
        const allTagIndex = allTags.value.findIndex((existingTag: Tag) => existingTag.id === tagId);
        if (allTagIndex !== -1) {
            allTags.value.splice(allTagIndex, 1);
        }
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Tag supprimé avec succès',
            life: 3000
        });
    }
}

const emit = defineEmits(['update:visible']);

const handleDialogHide = () => {
    emit('update:visible', false);
}

</script>